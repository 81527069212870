angular.module("mfgApp", ["common"])
    .config(translateConfig)
    .run(['$rootScope', '$translate', 'PageService', 'instructionsMFG',
        function ($rootScope, $translate, PageService, instructionsMFG) {

            (function setMFGInstructionPages() {
                PageService.addPages(instructionsMFG);
            })();

            $rootScope._ = _;
            $translate.refresh();
        }]);

